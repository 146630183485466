import React from "react"
import tw from "twin.macro"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

// Components
import Layout from "@components/layout"
import Seo from "@components/seo"
import Section from "@components/section"
import Container from "@components/container"
import ImageAndText from "@components/imageAndText"
import Anchor from "@components/anchor"
import BorderedCard from "@components/borderedCard"
import { Heading1, purpleButton } from "@styles/ui"
import InlineVideo from "@components/inlineVideo"

// Assets
import logo from "@images/logos/logo-colourful-outline.svg"
import jazzCaresLogo from "@images/jazzcares-for-xywav-page/jazzcares-logo.svg"
import nurse from "@images/icons/icon-nurse.svg"
import resources from "@images/icons/icon-hand-cog.svg"
import reimbursement from "@images/icons/icon-reimbursement.svg"
import battery from "@images/icons/icon-battery.svg"
import appleBtn from "@images/jazzcares-for-xywav-page/apple.svg"
import googleBtn from "@images/jazzcares-for-xywav-page/google.svg"
import BlurredBgCallout from "../components/blurredBgCallout"
import fiveDollarIcon from "@images/icons/financial-assistance/yellow/five-dollar.svg"
import voucherIcon from "@images/icons/financial-assistance/yellow/voucher.svg"
import bridgeIcon from "@images/icons/financial-assistance/yellow/bridge.svg"
import heartIcon from "@images/icons/financial-assistance/yellow/heart.svg"

const XywavAndXyremProgram = ({ location, data }) => {
  const imageAndTextDataOne = {
    image: jazzCaresLogo,
    altText: "",
    heading: (
      <>
        JazzCares for XYWAV is committed to helping get you and your patients{" "}
        <strong>support and resources throughout treatment</strong>
      </>
    ),
    paraOne:
      "Jazz Pharmaceuticals, the leader in sleep medicine, is committed to helping remove barriers to access for appropriate patients who may benefit from XYWAV.",
  }

  const firstCards = [
    {
      image: reimbursement,
      heading: (
        <>
          Access and Affordability<sup>*&dagger;</sup>
        </>
      ),
      text: (
        <>
          <p>
            Our teams work directly with your office to{" "}
            <strong>verify each patient&apos;s insurance coverage</strong> and
            support you and your staff through the insurance coverage process.
            Several <strong>financial assistance options</strong> are also
            available for eligible patients.
          </p>
        </>
      ),
    },
    {
      image: battery,
      heading: "Nurse and pharmacy support",
      text: (
        <>
          <p>
            A <strong>JazzCares Nurse Case Manager</strong> will be available
            for your patients from the very beginning and will provide{" "}
            <strong>support throughout treatment.</strong> A{" "}
            <strong>pharmacist is also available 24/7</strong> to answer
            patients&apos; questions.
          </p>
        </>
      ),
    },
    {
      image: resources,
      heading: "Personalized resources",
      text: (
        <>
          <p>
            <strong>JazzCares for XYWAV</strong> has the resources to help your
            patients by giving them{" "}
            <strong>personalized information and support</strong> throughout
            their treatment journey.
          </p>
        </>
      ),
    },
    {
      image: nurse,
      heading: "Post-prescription patient support",
      text: (
        <>
          <p>
            Give your patients support after their first prescription.{" "}
            <strong>JazzCares Nurse Educators</strong> offer{" "}
            <strong>
              collaborative care with your office following patient engagement
            </strong>{" "}
            to discuss patient&#8209;specific insights and strategies.
            They&apos;ll help patients stay on track with treatment and provide
            comprehensive information about JazzCares patient support programs,
            like the Mentor Program and the myWAV app.
          </p>
        </>
      ),
    },
  ]

  const secondCards = [
    {
      image: fiveDollarIcon,
      heading: <span tw="text-transform[none]">The XYWAV Coupon Program</span>,
      text: (
        <p>
          Did you know that &gt;99% of all commercial patients{" "}
          <strong>
            can pay as little as $5 for XYWAV?<sup>a,b</sup>
          </strong>
        </p>
      ),
      smallIcon: true,
    },
    {
      image: voucherIcon,
      heading: (
        <span tw="text-transform[none]">The XYWAV Quick Start Voucher</span>
      ),
      text: (
        <p>
          Waiting for your patients&apos; coverage? Your patients{" "}
          <strong>
            could receive a free 1-month supply of XYWAV with the Quick Start
            Voucher.<sup>a</sup>
          </strong>
        </p>
      ),
      smallIcon: true,
    },
    {
      image: bridgeIcon,
      heading: <span tw="text-transform[none]">The XYWAV Bridge Program</span>,
      text: (
        <p>
          Your patient{" "}
          <strong>
            may be eligible for up to 120 days of free XYWAV while waiting for
            coverage approval.<sup>a</sup>
          </strong>
        </p>
      ),
      smallIcon: true,
    },
    {
      image: heartIcon,
      heading: (
        <span tw="text-transform[none]">
          The XYWAV Patient Assistance Program
        </span>
      ),
      text: (
        <>
          <p tw="mb-1">
            The JazzCares Patient Assistance Program{" "}
            <strong>
              may be able to help when insurance coverage is an issue.
            </strong>
          </p>
          <p>
            Our Patient Assistance Program has helped hundreds of eligible
            patients get access to free medication.<sup>c,d</sup>
          </p>
        </>
      ),
    },
  ]

  return (
    <Layout
      location={location}
      copyright={
        <>
          © 2024 Jazz Pharmaceuticals plc or its subsidiaries{" "}
          <br tw="block md:hidden" />
          US-XYW-2200207 Rev0824
        </>
      }
    >
      <Seo
        title="Jazzcares® Patient and HCP Assistance Program | XYWAV HCP"
        description="JazzCares® for XYWAV® (calcium, magnesium, potassium, and sodium oxybates) is committed to helping you and your narcolepsy patients get support and resources throughout treatment. See XYWAV® oral solution full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />

      <Section
        addedStyles={tw`relative overflow-hidden cost-and-coverage-hero-gradient pb-[108px] pt-[45px] rounded-bl-[100px] md:(pt-20 pb-[100px]) xl:(rounded-bl-[150px])`}
      >
        <img
          src={logo}
          tw="absolute right-[-20px] bottom-[-70px] w-[278px] h-[220px] md:(w-[570px] h-[600px] right-[-65px] top-[-25px]) xl:(w-[770px] top-[25px])"
          alt=""
        />
        <Container>
          <p tw="mb-2.5 uppercase font-semibold text-xs text-merlin tracking-[0.6px] xl:(text-base)">
            JazzCares<sup>&reg;</sup> for Xywav
          </p>
          <h1
            css={[
              Heading1,
              tw`w-full max-w-[335px] leading-[29.62px] md:max-w-[444px] xl:(w-[unset] max-w-[670px] text-[40px] leading-[48px])`,
            ]}
          >
            <strong>
              JazzCares is your partner in supporting your patients throughout
              their treatment journey
            </strong>
          </h1>
        </Container>
      </Section>

      <Section addedStyles={tw`md:py-[50px] xl:(pt-[65px] pb-[24px])`}>
        <Container>
          <ImageAndText
            image={imageAndTextDataOne.image}
            heading={imageAndTextDataOne.heading}
            paraOne={imageAndTextDataOne.paraOne}
            altText={imageAndTextDataOne.altText}
          />
        </Container>
      </Section>

      <Section addedStyles={tw`bg-alabaster-2 xl:(pt-[65px] pb-10)`}>
        <Container>
          <p tw="pb-10">
            JazzCares works directly with your office to help verify insurance
            coverage and support you and your staff through the insurance
            coverage process. The JazzCares Nurse Case Manager complements your
            office and will be with patients from the very beginning and will
            provide support throughout their treatment journey. The Field Nurse
            Educator can help your office keep track of your patients&apos;
            progress and specific needs.
          </p>
          <div tw="flex flex-col gap-[28px] xl:(flex-row flex-wrap justify-center items-stretch)">
            {firstCards.map((card, index) => (
              <BorderedCard
                key={`financial-assistance-${index}`}
                cardData={card}
                addedStyles={tw`w-full rounded-[70px] background-image[linear-gradient(29.42deg, #f93549 7.67%, #ad95dc 51.86%, #ffbf3c 87.52%)] xl:(w-[calc(50% - 14px)] py-[26px] px-[46px] h-auto min-h-[339px] flex flex-col items-center justify-start) before:(content[''] absolute left-0 right-0 top-0 bottom-0 m-[4px] bg-white rounded-[67px] xl:rounded-[57px])`}
              />
            ))}
          </div>
          <p tw="mt-10">
            <Anchor
              link="https://jazzcares.com/hcp/"
              isExternal
              newTab
              underline
            >
              <strong>Learn more</strong>
            </Anchor>
            <strong> about JazzCares offerings</strong>
          </p>
          <div tw="flex mt-5 text-[12px]">
            <p>*</p>
            <p>
              Insurance coverage and plans may vary. The JazzCares program at
              Jazz Pharmaceuticals provides general information only and is not
              a guarantee of any coverage or reimbursement outcome. All
              treatment decisions rest solely with the treating physician or
              qualified healthcare professional.
            </p>
          </div>
          <div tw="flex mt-5 text-[12px]">
            <sup tw="font-semibold leading-none">&dagger;</sup>
            <p>
              See{" "}
              <Anchor
                link="https://www.jazzcares.com/hcp/xywav#termsandconditions"
                isExternal
                newTab
                underline
              >
                Terms and Conditions
              </Anchor>{" "}
              for eligibility.
            </p>
          </div>
        </Container>
      </Section>

      <Section
        sectionId="financial-assistance"
        addedStyles={tw`bg-twilight-blue xl:(pt-[65px] pb-10)`}
      >
        <Container
          addedStyles={tw`bg-white px-5 pt-14 pb-8 rounded-tl-[50px] rounded-br-[50px] xl:(max-w-[1320px] px-[74px] pb-[32px] rounded-tl-[130px] rounded-br-[130px])`}
        >
          <h2 tw="font-semibold text-4xl mb-4">
            XYWAV financial assistance programs through JazzCares
          </h2>
          <h3 tw="text-[18px] mb-8">
            JazzCares may help find a financial assistance program that fits the
            needs of your patients
          </h3>
          <div tw="flex flex-col gap-[28px] xl:(flex-row flex-wrap justify-center items-stretch)">
            {secondCards.map((card, index) => (
              <BorderedCard
                key={`financial-assistance-${index}`}
                cardData={card}
                addedStyles={tw`w-full rounded-[70px] background-image[linear-gradient(29.42deg, #f93549 7.67%, #ad95dc 51.86%, #ffbf3c 87.52%)] xl:(w-[calc(50% - 14px)] py-[26px] px-[46px] h-auto min-h-[339px] flex flex-col items-center justify-start) before:(content[''] absolute left-0 right-0 top-0 bottom-0 m-[4px] bg-white rounded-[67px] xl:rounded-[57px])`}
              />
            ))}
          </div>

          <div tw="flex items-center justify-center my-10 xl:my-[70px]">
            <Anchor
              link="https://jazzcares.com/patients/xywav/"
              isExternal
              newTab
              addedStyles={[
                purpleButton,
                tw`mx-auto px-5 py-4 inline-block xl:(px-10 py-5)`,
              ]}
            >
              Get Started With Jazzcares
            </Anchor>
          </div>

          <div tw="max-w-[975px] mx-auto text-[12px]">
            <ul>
              <li tw="text-indent[-5px] pl-[5px]">
                <sup>a</sup>Applies only to eligible, commercially insured
                patients enrolled in the XYWAV and XYREM&nbsp;REMS.
              </li>
              <li tw="text-indent[-7px] pl-[7px]">
                <sup>b</sup>Eligible patients pay as little as $5. Subject to
                annual maximum&nbsp;benefit.
              </li>
              <li tw="text-indent[-5px] pl-[5px]">
                <sup>c</sup>Subject to financial and other eligibility criteria.
                Must be enrolled in the XYWAV and XYREM&nbsp;REMS.
              </li>
              <li tw="text-indent[-7px] pl-[7px]">
                <sup>d</sup>The Patient Assistance Program application is
                available online at{" "}
                <Anchor
                  link="https://www.jazzcares.com/xywav"
                  isExternal
                  newTab
                  underline
                >
                  www.jazzcares.com/xywav
                </Anchor>
              </li>
            </ul>
            <p tw="mt-6">
              Jazz Pharmaceuticals reserves the right to terminate or modify at
              any time with or without notice. Other{" "}
              <Anchor
                link="https://jazzcares.com/hcp/xywav/#termsandconditions"
                isExternal
                newTab
                underline
              >
                Terms and Conditions
              </Anchor>
              &nbsp;apply.
            </p>
          </div>
        </Container>
      </Section>

      <Container addedStyles={tw`w-full`}>
        <BlurredBgCallout agnostic>
          <h2
            css={[
              tw`mb-5 mt-5 font-semibold text-center text-merlin text-4xl xl:(mt-0 mb-[35px] text-[26px])`,
            ]}
          >
            Have additional questions?
          </h2>
          <p tw="text-center text-merlin text-base xl:text-3xl">
            Contact your Jazz Pharmaceuticals Access and Reimbursement Manager,
            visit{" "}
            <Anchor
              link="https://jazzcares.com/"
              isExternal
              newTab
              underline
              addedStyles={tw`font-semibold`}
            >
              JazzCares.com
            </Anchor>
            , or call&nbsp;
            <a href="tel:1-866-997-3688" tw="underline font-semibold">
              <nobr>1-866-997-3688</nobr>
            </a>
            , <nobr>Monday – Friday,</nobr>
            <nobr> 7 AM – 8 PM CT.</nobr>
          </p>
        </BlurredBgCallout>
      </Container>

      <Section id="mywav" addedStyles={tw`pt-0`}>
        <Container addedStyles={tw`text-merlin`}>
          <div
            id="mywav"
            tw="flex flex-col xl:(flex-row justify-center items-center gap-14 mx-auto px-4) xl:(px-0 pb-0 gap-[130px])"
          >
            <div tw="mb-[30px] w-[calc(100% + 35px)] ml-[-17.5px] xl:(min-w-[670px] mb-0)">
              <InlineVideo
                wistiaId={"jmu91q3egm"}
                thumbnail={data.videoThumbnail}
                eyebrowText={""}
                singleCol
                videoName="myWAV: Smart support at your patients' fingertips"
              />
            </div>
            <div tw="mb-7 xl:(text-left mb-0 max-w-[370px] px-0)">
              <div tw="font-bold text-crimson uppercase text-base mb-[25px] xl:font-semibold">
                Watch Video
              </div>
              <h2 tw="mb-3.5 text-2xl font-semibold xl:(text-4xl mb-6 mt-0)">
                myWAV: Smart support at your patients&apos; fingertips
              </h2>
              <p tw="mb-0 font-light xl:mb-6">
                Watch this video to find out how the myWAV app can offer your
                patients educational resources and individualized support
                throughout their treatment journey.
              </p>
            </div>
          </div>
          <div tw="xl:(grid grid-cols-12 gap-x-12 mt-[50px])">
            <div tw="xl:(col-span-5)">
              <h2 tw="font-semibold text-2xl text-center mb-[25px] xl:(text-4xl text-left)">
                For patients who&apos;ve been prescribed XYWAV, myWAV offers
                continued support
              </h2>
              <div tw="w-[70px] h-[5px] gradient3 mx-auto mb-[15px] xl:mx-[unset]" />
            </div>
            <div css={[tw`xl:(text-left col-start-6 col-span-7)`]}>
              <p>
                <strong tw="font-semibold">myWAV is easy to use and</strong>
              </p>

              <ul tw="list-disc padding-inline-start[20px] text-base mt-2.5 mb-[30px] xl:(mt-4)">
                <li>
                  <strong tw="font-semibold">HELPS</strong> your patients get
                  started and track their symptoms
                </li>
                <li tw="mt-4">
                  <strong tw="font-semibold">ORGANIZES</strong> instructions for
                  use, support programs, treatment tips, and more
                </li>
                <li tw="mt-4">
                  <strong tw="font-semibold">SUPPORTS</strong> your patients
                  throughout their treatment journeys
                </li>
              </ul>
            </div>
          </div>
          <div tw="flex flex-col justify-center text-center xl:(mt-[30px] text-base)">
            <p>
              <strong>
                Get started today! Encourage your patients to register for
                support.
              </strong>
            </p>
            <div tw="flex flex-col mx-auto gap-x-[25px] mt-[15px] xl:(flex-row mt-[25px])">
              <Anchor
                link="https://apps.apple.com/us/app/mywav/id1534619555"
                isExternal
                newTab
              >
                <img
                  tw="w-full mb-[25px] xl:(w-[260px] mb-0)"
                  src={appleBtn}
                  alt="Apple App Store"
                />
              </Anchor>
              <Anchor
                link="https://play.google.com/store/apps/details?id=com.jazzpharma.mywav&pli=1"
                isExternal
                newTab
              >
                <img
                  tw="w-full xl:w-[260px]"
                  src={googleBtn}
                  alt="Get it on Google Play"
                />
              </Anchor>
            </div>
            <p tw="mt-5 text-[12px]">
              All third-party product, company names and logos are
              trademarks&trade; or registered<sup>&reg;</sup> trademarks and
              remain the property of their respective holders.
            </p>
          </div>
        </Container>
      </Section>
    </Layout>
  )
}

export default XywavAndXyremProgram

XywavAndXyremProgram.propTypes = {
  location: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query {
    videoThumbnail: file(
      relativePath: { eq: "jazzcares-for-xywav-page/mywav-thumbnail.jpg" }
    ) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
  }
`
