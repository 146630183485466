import React from "react"
import "twin.macro"
import PropTypes from "prop-types"

const imageAndText = ({
  image,
  altText,
  heading,
  paraOne,
  paraTwo,
  ulItems,
  olItems,
}) => {
  return (
    <div tw="mt-6 md:(flex items-start justify-between mt-0)">
      <div tw="rounded-[0px 140px 50px 95px] flex items-center justify-center max-w-[470px] flex-1 md:(justify-start items-start)">
        <img src={image} alt={altText} tw="md:w-[340px] xl:w-[470px]" />
      </div>

      <div tw="mt-5 md:(mt-0 w-[340px]) xl:(w-[48%] self-center pr-[40px])">
        <h3 tw="text-4xl">{heading}</h3>
        {paraOne && <p tw="pt-5">{paraOne}</p>}
        {ulItems && (
          <ul tw="list-outside padding-inline-start[18px] mt-5">
            {ulItems &&
              ulItems.map((item, i) => (
                <li
                  key={i}
                  tw="mb-1.5 relative before:(content['&bull;'] absolute left-[-18px])"
                >
                  {item}
                </li>
              ))}
          </ul>
        )}
        {olItems && (
          <ol tw="padding-inline-start[8px] mt-4">
            {olItems.map((item, i) => (
              <li key={i} tw="mb-1.5 relative">
                {item}
              </li>
            ))}
          </ol>
        )}
        {paraTwo && <p>{paraTwo}</p>}
      </div>
    </div>
  )
}

export default imageAndText

imageAndText.propTypes = {
  image: PropTypes.string.isRequired,
  altText: PropTypes.string,
  heading: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired,
  ]),
  paraOne: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired,
  ]),
  paraTwo: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired,
  ]),
  ulItems: PropTypes.array,
  olItems: PropTypes.array,
}

imageAndText.defaultProps = {
  altText: "",
  heading: "",
  paraOne: "",
  paraTwo: "",
}
